<template>
  <div style="background-color: #f1faff">
    <div>
      <component v-bind:is="loadComponent"></component>
    </div>
    <!-- :key="$route.path"-->
    <router-view></router-view>
    <!-- <go-top :size = 45 bg-color="#209cee" right="95%" class="leftrightmargin"></go-top> -->
  </div>
  <vue-scroll-up
    v-slot="{ isTop }"
    tag="div"
    custom-class="my-scroll-up"
    :scroll-duration="1000"
    :scroll-y="250"
    :always-show="false"
  >
    {{ isTop ? "👇" : "↑" }}
  </vue-scroll-up>
  <!-- top% should be caluclated but this is okay for now-->
  <div class="footerSection">
  <footer
    :class="['is-fullwidth', currentColor]"
    style="margin-top: 100px"
    v-if="!isHide"
  >
    <div class="columns mx-auto" style="width: 80%">
      <div class="column is-narrow" style="vertical-align: middle">
        <img src="@/assets/images/logos/footerlogo.png" alt="" />

        <img
          src="@/assets/images/logos/footer.png"
          alt=""
          style="margin-top: 0px; margin-inline-start: 10px"
        />
      </div>
      <div class="column is-offset-2-desktop is-offset-1-tablet">
        <p
          style="
            font-weight: 400;
            font-size: 20px;
            line-height: 1.25em;
            text-align: justify;
            color: #232323;
          "
        >
          {{ $t("About Message") }}
        </p>
      </div>
    </div>
    <div class="mx-auto" style="width: 80%">
      <hr
        style="border: 1px solid #157fed; background-color: #157fed"
        class=""
      />
    </div>

    <div class="level mx-auto" style="height: 50px; width: 80%">
      <div class="level-left">
        <p
          class="level-item"
          style="
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            text-align: right;
            color: #585858;
          "
        >
          {{ $t("All rights are saved ") }},{{
            $t("Egyptian Ministry of Environment")
          }}
        </p>
      </div>
      <div class="level-right">
        <div class="level-item">
          <img
            class="leftrightmargin"
            src="@/assets/icons/social/linkedin.png"
            alt=""
            style="background-color: transparent"
          />

          <img
            class="leftrightmargin"
            src="@/assets/icons/social/instagram.png"
            alt=""
            style="background-color: transparent"
          />

          <img
            class="leftrightmargin"
            src="@/assets/icons/social/pinterest.png"
            alt=""
            style="background-color: transparent"
          />

          <img
            class="leftrightmargin"
            src="@/assets/icons/social/twitter.png"
            alt=""
            style="background-color: transparent"
          />

          <img
            class="leftrightmargin"
            src="@/assets/icons/social/facebook.png"
            alt=""
            style="background-color: transparent"
          />
        </div>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const NavBar = defineAsyncComponent(() =>
  import("./components/navbar/NavBar.vue")
);

const UserNavBar = defineAsyncComponent(() =>
  import("./components/navbar/UserFacilityNavBar.vue")
);
import VueScrollUp from "vue-scroll-up";

const directions = {
  en: "ltr",
  ar: "rtl",
};

export default {
  name: "App",
  components: {
    NavBar,
    UserNavBar,
    VueScrollUp,
  },
  computed: {
    isHide() {
      return this.$route.name == "Login";
    },
    currentColor() {
      if (!this.$store.state.isLoggedIn) {
        return "graybackground";
      } else return "is-white";
    },
    direction() {
      return directions[this.$i18n.locale];
    },
    loadComponent() {
      let type = this.$store.state.user.type;
      switch (type) {
        // case "waste user":
        //   return "DumpUserNavigation";
        case "facility owner":
        //return "UserNavBar";
        case "water user":
        case "super":
        case "admin":
        case "decision maker":
        case "content creator":
        case "datacollector":
        case "data verifier":
        case "form filler supervisor":
        default:
          return "NavBar";
      }
    },
  },
  methods: {
    changeLanguageDirection(value) {
      document.dir = value;

      var oldlink = document.getElementById("main-style");
      oldlink.setAttribute(
        "href",
        value == "rtl"
          ? `${process.env.BASE_URL}css/app.rtl.css`
          : `${process.env.BASE_URL}css/app.css`
      );
      //this.aligment()
    },
  },
  watch: {
    direction(value) {
      this.changeLanguageDirection(value);
    },
  },
};
</script>
<style>

.my-scroll-up {
  align-items: center;
  background-color: #157fed;
  border: rgba(17, 73, 227, 0.886);
  border-radius: 100px;
  bottom: 100px;
  color: white;
  cursor: pointer;
  display: flex;
  height: 55px;
  justify-content: center;
  position: fixed;
  left: 25px;
  width: 55px;
  z-index: 99;
  font-size: xx-large;
  font-weight: bold;
  transition: all 1s ease-out;
}
/* .graybackground {
  background-color: #ECECEC;
} */

@media screen and (max-width: 450px) {
  .vue-scroll-up {
    bottom: 10px;
    transform: translateX(5px) scale(0.7);
  }
}
</style>
