// import Vue from "vue";

export const formOptions = {
  namespaced: true,
  state:()=>( {
    options: {},
  }),
  mutations: {
    setOption(state, payload) {
      if (payload.id in state.options) {
        state.options.id = payload.data;
      } else {
        // Vue.set(state.options, payload.id, payload.data);
        state.options[payload.id]=payload.data;
      }
    },
  },
  getters: {
    getOption: (state) => {
      return state.options;
    },
  },
};