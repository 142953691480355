export default {
  "Create a New User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مستخدم جديد"])},
  "Create a New User Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء نوع مستخدم جديد"])},
  "Search User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن مستخدم"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
  "At least eight characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثمانية أحرف على الأقل"])},
  "one lower character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرف صغير   "])},
  "one upper character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرف كبير   "])},
  "one number character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم   "])},
  "one special character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامة ترقيم   "])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور الجديدة"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفة"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
  "Main Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع اساسي"])},
  "Secondary Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع ثانوي"])},
  "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشرف"])},
  "Data Verifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجع البيانات"])},
  "Decision Maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صانع القرار"])},
  "Department Verifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجع الأقسام"])},
  "Department Viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عارض الأقسام"])},
  "Department Viewer Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عارض أقسام المحافظة"])},
  "Facility Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالك المنشآة"])},
  "Form Filler Supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشرف علي جامعي بيانات"])},
  "DataCollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جامع البيانات"])},
  "super": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوبر"])},
  "Waste User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم المخلفات"])},
  "Water User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم مياه"])},
  "User Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
  "Create Authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء جهة مالكة جديدة"])},
  "do you want to add title?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد إضافة عنوان؟"])},
  "Authorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السُلطات"])},
  "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "Authority Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الجهة المالكة"])},
  "Search Authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن جهة مالكة"])},
  "Authority Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الجهة المالكة"])},
  "at least one field must have a value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر سلطة واحدة على الأقل"])},
  "the authority title already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان السلطة متكرر"])},
  "Delete action was canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم حذف المنشأة"])}
}