export default {
  "Homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة الرئيسية"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدمين"])},
  "Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشآت"])},
  "Sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاعات"])},
  "Create a Super Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء قطاع رئيسي"])},
  "Create a Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء قطاع"])},
  "Create a Sub Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء قطاع فرعي"])},
  "Create an industrial Semi-Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء قطاع صناعي مجمع"])},
  "Laws and Regulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللوائح والقوانين"])},
  "Upload Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الملفات"])},
  "Download Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الملفات"])},
  "Upload Maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الخرائط"])},
  "Log Out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخروج"])},
  "Sewage Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الصرف"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي"])},
  "Number of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعداد"])},
  "Pollutant Loads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحمال ملوثات"])},
  "Total Water Pollutant Loads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي أحمال ملوثات المياه"])},
  "per government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالمحافظة"])},
  "Total Air Pollutant Loads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي أحمال ملوثات الهواء"])},
  "Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
  "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحباً"])},
  "agriculture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاصيل الزراعية"])},
  "Agriculture and Animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزراعة والماشية"])},
  "Add Agriculture Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة بيانات المحصول"])},
  "Add Animals Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة بيانات الماشية"])},
  "Show Agriculture Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المحاصيل الزراعة"])},
  "Search CropsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن بيانات المحاصيل"])},
  "Show Animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عرض الماشية والدواجن"])},
  "Search Animals Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن بيانات الحيوانات"])},
  "Agriculture Pollution Calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب ملوثات الزراعة"])},
  "Sewage Pollution Calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب ملوثات الصرف"])}
}