export default {
  "Scanned Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت الممسوحة"])},
  "SectorRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قواعد القطاع"])},
  "Control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحكم"])},
  "Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقود"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصنفات"])},
  "Agriculture data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الزراعة والتربية"])},
  "Upload Agriculture Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع القطاعات الزراعية"])},
  "Upload LiveStock Distribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع جداول توزيع المزارع"])},
  "Upload Agriculture data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع بيانات الزراعة"])},
  "Agriculture Calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابات الزراعة"])},
  "Upload Agriculture Equations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع معادلات الزراعة"])},
  "Agriculture Requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات بيانات الزراعة"])},
  "Administrative Boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود الادارية"])},
  "Please note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ملاحظة"])},
  "Use the Excel template table and do not edit the tab names.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الالتزام بجدول البيانات المرفق وعدم إجراء أي تعديلات على أسماء التبويبات "])},
  "Agriculture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزراعة"])},
  "Agriculture File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات الزراعة"])},
  "Waste File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات النفايات"])},
  "Charts and Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم البيانية والتقارير"])},
  "Agriculture and Animals Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الزراعة والماشية "])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
  "Dashboard report per sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير لوحة التحكم حسب القطاع"])},
  "Dashboard report per governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير لوحة التحكم حسب المحافظة"])},
  "You can import .xls and .xlsx files only...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك رفع الملفات بصيغة xls. xlsx. فقط"])},
  "You can import .zip, .shp and .json files only...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك رفع الملفات بصيغة  zip. و داخله shp. او json. فقط"])},
  "Agriculture Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الزراعي"])},
  "Agriculture Crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحصول الزراعي"])},
  "Map Grids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خرائط مقسمة"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "Ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجاهل الاختلافات"])},
  "delete data and add new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف البيانات و اضافه جديده"])},
  "Upload a new map...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع خريطة اراضي زراعية جديدة"])},
  "pollution loads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحمال التلوث"])},
  "lookup data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات مساعدة"])},
  "Annual Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات سنوية"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا"])},
  "Upload Composting Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" رفع مصانع الأسمدة"])},
  "Upload Landfills and DumpSites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع مكبات ومقالب النفايات"])},
  "Upload Waste Water Treatement Plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع محطة معالجة الصرف الصحي"])},
  "Upload Misc Annual Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع بيانات سنوية "])},
  "Upload Solid Waste Sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع قطاعات المخلفات الصلبة"])},
  "Upload Solid Waste Equations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع معادلات المخلفات الصلبة"])},
  "Solid Waste Pollution Calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حساب ملوثات المخلفات الصلبة"])}
}