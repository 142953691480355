export default {
  "Add a New Law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قانون جديد"])},
  "Change a Current Law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير قانون الحالي"])},
  "Delete a Current Law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء قانون الحالي"])},
  "No Files Selected...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد ملفات مختارة..."])},
  "Click or Drop Files...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الملفات"])},
  "You can import .pdf files only...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك رفع الملفات بصيغة pdf. فقط"])},
  "Files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات"])},
  "Maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخرائط"])},
  "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات"])},
  "No attachments.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".لا يوجد مستندات مرفقة"])},
  "Error while returning files. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر تحميل المستندات المرفقة. حاول مرة أخري."])},
  "Import All ...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل كل الملفات"])},
  "Import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل"])},
  "Rename File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم جديد للملف"])},
  "File Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الملف"])},
  "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
  "Upload All...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الكل..."])},
  "Succeeded in deleting law.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الملف بنجاح."])},
  "Error in deleting law. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر عملية حذف الملف."])},
  "Failed to upload file. File name should contain exactly one dot.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل تحميل الملف. يجب أن يحتوي اسم الملف على نقطة واحدة بالضبط."])}
}