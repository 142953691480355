export default {
  "Create a New Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء منشأة جديدة"])},
  "Edit Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تعديل منشأة"])},
  "Search Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البحث عن منشأة"])},
  "Delete Multiple Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف عدة منشآت"])},
  "Facility ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم التعريفي للمنشأة"])},
  "Facility Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود التعريفي للمنشأة"])},
  "Facility Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنشأة"])},
  "View Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المنشأة"])},
  "Application Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الاستمارة"])},
  "Application Rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الاستمارة"])},
  "Form Creation Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ إنشاء الاستمارة"])},
  "Visiting Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الزيارة"])},
  "Form Filler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مالئ الاستمارة"])},
  "Type of Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الزيارة"])},
  "Chairman Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم جهة الاتصال"])},
  "Chairman Job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وظيفة صاحب جهة الاتصال"])},
  "Chairman Telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تليفون صاحب جهة الاتصال"])},
  "Representative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم ممثل النشاط البيئي"])},
  "Licensed Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط المرخص"])},
  "Year of Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة التشغيل"])},
  "Average Working Days per Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط ايام العمل بالسنة"])},
  "Total number of workers in all shifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجمالي عدد العمال في كل الورديات"])},
  "Number of Work Hours per Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد ساعات الشغل في الوردية"])},
  "Does it have Environmental Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه سجل بيئي"])},
  "Does it have Furnaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه أفران"])},
  "Does it have Boilers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه غلايات"])},
  "Does it have Chimneys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه مداخن"])},
  "Does it have Open Storage Spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مساحات تخزين مكشوفة"])},
  "Does it have Liquid Organic Tanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه تنكات مواد عضوية سائلة"])},
  "Does it have Industrial Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد صرف صناعي"])},
  "Does it have Water Pollutants Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه قياسات ملوثات مياه "])},
  "Does it have Air Pollutants Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه قياسات ملوثات هواء"])},
  "Facility Is Not Submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تسليم الاستمارة"])},
  "Main Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الرئيسية"])},
  "Attach Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرفقات "])},
  "Production Lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطوط الانتاج"])},
  "Boilers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غلايات"])},
  "Chimneys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المداخن"])},
  "Open Storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخزين مكشوف"])},
  "Tanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنكات مواد عضوية"])},
  "Water Sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر المياه"])},
  "Water Usage and Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف الصناعي والصحي"])},
  "Energy Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصدر الطاقة"])},
  "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القياسات"])},
  "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "Pollutants Aggregation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الملوثات"])},
  "Electricity Bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاتورة كهرباء"])},
  "Water Bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاتورة مياه"])},
  "Gas Bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاتورة غاز"])},
  "Solid Materials Disposal Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستندات التخلص من المواد الصلبة"])},
  "Hazardous Waste Tracking Certificate for Each Shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة  تتبع المخلفات الخطرة لكل شحنة"])},
  "Flow Sheets Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process flowsheet لكل خط"])},
  "Other Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستندات أخري "])},
  "Production Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الانتاج"])},
  "Sectors and Industrial Processes Classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف القطاعات والعمليات الصناعية "])},
  "Industrial SubProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية الصناعة الفرعية"])},
  "Industrial SubSubProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية الصناعة الدقيقة"])},
  "Industrial Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية الصناعية"])},
  "Production Line Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم خط الانتاج"])},
  "Number of similar lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الخطوط المشابهة"])},
  "Boilers Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الغلايات"])},
  "Delete Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المنشأة"])},
  "Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشأة"])},
  "Facilities to be deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت التي سيتم حذفها"])},
  "Confirm Deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الحذف"])},
  "Clear List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح القائمة"])},
  "Location and coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع و الاحداثيات"])},
  "Contact data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات جهة الاتصال"])},
  "Work and activity details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل العمل و النشاط"])},
  "Additional specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواصفات اضافية"])},
  "Number of Coal bags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد شكارات الفحم"])},
  "Leachate Production Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل إنتاج المادة المرتشحة"])},
  "Port capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة الميناء"])},
  "Port capacity (design)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة الميناء(التصميمية)"])},
  "Port capacity (actual)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة الميناء(الفعلية)"])},
  "Dry casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصب الجاف"])},
  "Liquid casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصب السائل"])},
  "Casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصب"])},
  "Water quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جودة المياة"])},
  "Port basin depth (m)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمق حوض الميناء (م)"])},
  "Water space (km2)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساحة المائية (كم2)"])},
  "Floor space (km2)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساحة الأرضية (كم2)"])},
  "Total port area (km2)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي مساحة الميناء (كم2)"])},
  "Service area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منطقة الخدمة"])},
  "Does it have Design Port Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديها سعة منفذ التصميم"])},
  "Does it have Actual Port Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديها سعة المنفذ الفعلية"])},
  "Does it have Dry Casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد صب جاف"])},
  "Does it have Liquid Casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد صب سائل"])},
  "Does it have Water Quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد محطة معالجة صرف صحي "])},
  "Does it have Industrial Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد منشأة صناعية"])},
  "Does it have Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مخلفات"])},
  "Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات"])},
  "Water Quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جودة المياة"])},
  "Industrial facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشأت صناعية"])},
  "Number of berths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الارصفة"])},
  "Type of sidewalks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الأرصفة"])},
  "Pavement space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة الأرصفة"])},
  "Containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحاويات"])},
  "Number/year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد/سنة"])},
  "Quantity of materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المواد "])},
  "million tons/year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مليون طن/سنة"])},
  "Materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد"])},
  "Type of materials traded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المواد المتداولة"])},
  "Is there dry casting?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد صب جاف"])},
  "Quantity of materials handled by dry casting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المواد المتداولة بالصب الجاف"])},
  "Is there a storage area?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد منطقة تخزين"])},
  "Storage area space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة منطقة التخزين"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طول"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "Is the storage area uncovered?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل منطقة التخزين مكشوفة"])},
  "Stored materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد المخزنة في التخزين مكشوفة"])},
  "Control devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة التحكم"])},
  "Is there liquid casting?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد صب سائل"])},
  "Are there organic tanks?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد تنكات عضوية"])},
  "Are there liquid waste?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مخلفات سائلة"])},
  "Liquid Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات السائلة"])},
  "How to handle liquid waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية التعامل مع النفايات السائلة"])},
  "Are there solid waste?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مخلفات صلبة"])},
  "How to handle solid waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية تداول المخلفات الصلبة"])},
  "Solid Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات الصلبة"])},
  "Is it a continuation of another line?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل استكمال لخط اخر "])},
  "Is there a sewage treatment plant?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد محطة معالجة صرف صحي؟ "])},
  "How to dispose of sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية التخلص من الصرف الصحي"])},
  "Does he find an environmental performance report?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يجد تقرير للأداء البيئي؟"])},
  "Is there industrial facility inside port?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد منشأة صناعية داخل الميناء"])},
  "Industrial Facility Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المنشآت الصناعية"])}
}