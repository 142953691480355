export default {
  "Detailed Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريطة مفصلة"])},
  "Collective Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريطة مجمعة"])},
  "Expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصارف"])},
  "Advanced Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريطة متقدمة"])},
  "Electricity and power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع الكهرباء و الطاقة"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول"])},
  "Charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم بيانية"])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخراج"])},
  "Government Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقليم"])},
  "Danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الخطورة"])},
  "Main drain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصرف الأساسي"])},
  "Sub Sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاعات فرعية"])},
  "Cannot filter by sub sub sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن التصفية بواسطة هذا القطاع الفرعي"])},
  "No data found!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بيانات"])},
  "Please choose a sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر قطاع"])},
  "Please choose a sub sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر قطاع فرعي"])},
  "Type Of Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البحث"])},
  "Facility Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث التفصيلي"])},
  "Collective Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث المجمع"])},
  "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])},
  "Deep Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل دقيقة"])},
  "Facilities Air Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المنشأة الخاصة بالهواء"])},
  "Main Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الرئيسية"])},
  "Chimneys Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المداخن"])},
  "Inventories Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المخازن"])},
  "Waste Water Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات مياه الصرف"])},
  "Export Facilities Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخراج بيانات المنشآت"])},
  "License": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترخيص"])},
  "Classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف"])},
  "Search Configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات البحث"])},
  "Delete current saved option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الإعداد المحفوظ الحالي"])},
  "Failed to delete this option. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر حذف هذا الإعداد. من فضلك أعد المحاولة لاحقا"])},
  "Choose from your saved configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من إعدادات بحثك المحفوظة"])},
  "saved configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات محفوظة"])},
  "Reset search options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين اختيارات البحث"])},
  "Search by ID...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث بالرقم التعريفي"])},
  "Search by name...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث بالاسم"])},
  "Primary Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الرئيسي"])},
  "All Sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع القطاعات"])},
  "Form Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاله البيانات"])},
  "Verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت المراجعة"])},
  "Need Verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحتاج مراجعة"])},
  "Rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحتاج تعديل"])},
  "Not Submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم التسليم"])},
  "Location Outside Egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع خارج الجمهورية"])},
  "Real Lat And Lat Are Significanlly Different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدم تطابق خطوط الطول والعرض الحقيقية"])},
  "Failure To Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في تسليم الاستمارة"])},
  "Long Time With No Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم التسليم لوقت طويل"])},
  "Wrong Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في المحافظة"])},
  "Pollutants Calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الملوثات"])},
  "Problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشكلات"])},
  "Pollutants by sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملوثات بالقطاع"])},
  "Facilities by sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت بالقطاع"])},
  "Pollutants geographically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملوثات جغرافيًا"])},
  "Facilities geographically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت جغرافيًا"])},
  "No data to show. Please search first or try to use different search options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات لعرضها، من فضلك قم بالبحث أولا أو اختيار إعدادات بحث مختلفة."])},
  "Status of Visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الزيارة"])},
  "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تجميع البيانات"])},
  "Delayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤجلة"])},
  "Closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلقة"])},
  "Refused Cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض التعاون"])},
  "Under Construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحت الانشاء"])},
  "Inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخزن"])},
  "Must choose a super sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب اختيار قطاع رئيسي"])},
  "Select number of classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار عدد الفئات"])},
  "Your request has been sent. Please wait.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال طلبكم. برجاء الانتظار."])},
  "max value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكبر قيمة"])},
  "min value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقل قيمة"])},
  "Statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات"])},
  "Governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محافظات"])},
  "All NFR's": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الفئات"])},
  "No data available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات"])}
}