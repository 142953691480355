import axios from 'axios';

export default axios.create({
  // Url of the Server, you will find URL configered in .env.[mode] file.
  baseURL: process.env.VUE_APP_SERVICE_URL,

  // To set the content type for the request/response
  headers: {
    'Accept': 'application/json',
    // 'Content-Type': 'application/json;charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest',
  },

  // This is to send the session alongside the request
  withCredentials: true,
});