export default {
  "Egypt Pollution Inventory Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشروع جرد و حصر الملوثات"])},
  "About Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن البرنامج"])},
  "We welcome your feedback and questions. Please feel free to contact us through our website or by phone or email. Our team is ready to assist you with any inquiries you may have about our program, our research, or environmental issues in general. Thank you for your interest in the Egyptian National Pollutants' Inventory Program.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نرحب بتعليقاتكم وأسئلتكم. يرجى الاتصال بنا عبر موقعنا على الويب أو عبر الهاتف أو البريد الإلكتروني أو المساعد الآلي الموجود يمين الصفحة طوال أيام الأسبوع. فريقنا مستعد لمساعدتك في أي استفسارات قد تكون لديكم حول برنامجنا أو أبحاثا أو التعاون معنا في القضايا البيئية بشكل عام. شكرًا لاهتمامكم بالبرنامج الوطني للبيانات البيئية لجمهورية مصر العربية. "])},
  "Stay up-to-date with the latest news and events from the Egyptian National Pollutants' Inventory Program. Our news section provides timely and relevant information on environmental issues, policy updates, and program activities. We encourage you to visit this section regularly to stay informed about our work and the state of the environment in Egypt.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابقوا على اطلاع دائم بأحدث الأخبار والأحداث من البرنامج الوطني للبيانات البيئية لجمهورية مصر العربية. يوفر قسم الأخبار لدينا معلومات موجهة ومتعلقة بالقضايا البيئية والتحديثات السياسية وأنشطة البرنامج. نشجعكم على زيارة هذا القسم بانتظام للبقاء على اطلاع بعملنا وحالة البيئة في مصر."])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
  "Publication Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن فخورون بمشاركة نتائج أبحاثنا وتقاريرنا مع الجمهور. تغطي منشوراتنا مجموعة واسعة من الموضوعات المتعلقة بحماية البيئة، بما في ذلك جودة الهواء وإدارة المياه وإدارة المخلفات. نتعاون أيضًا مع المنظمات الوطنية والدولية لإنتاج منشورات مشتركة تسهم في  تحقيق التزامات الإفصاح البيئي على المستوى الدولي."])},
  "Organizational Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتكون فريقنا من محترفين عاليي الكفاءة ملتزمين بحماية البيئة وتعزيز التنمية المستدامة. نحن نعمل عن كثب مع المؤسسات الحكومية والمنظمات غير الحكومية وأصحاب المصلحة الآخرين لضمان تناغم جهودنا مع السياسات واللوائح البيئية الوطنية والدولية. فريقنا ملتزم بالشفافية والاحترافية في جميع أنشطتنا."])},
  "About Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج الوطني للبيانات البيئية لجمهورية مصر العربية هو نظام شامل مصمم لتقييم مستويات التلوث في مصر. يركز برنامجنا على التلوث الهوائي والمائي وإدارة النفايات. نقوم بإجراء الأبحاث وجمع البيانات وتحليل المعلومات لمساعدة صناع القرار على اتخاذ قرارات مستنيرة فيما يتعلق بحماية البيئة. هدفنا هو تعزيز التنمية المستدامة وحماية الصحة العامة."])},
  "Welcome Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبًا بكم في البرنامج الوطني للبيانات البيئية لجمهورية مصر العربية, الذي يهدف إلى توفير منصة موحدة لبيانات مصادر التلوث لدعم التخطيط والنمو المستدام في مصر. يلتزم البرنامج بتوفير معلومات دقيقة وحديثة حول مصادر التلوث المؤثرة على حالة البيئة وتأثيرها على الصحة العامة والأنشطة المختلفة. نحن نؤمن بأنه من خلال العمل معًا، يمكننا خلق بيئة جيدة ومستقبل صحي ومستدام للجميع."])},
  "Publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات والتقارير"])},
  "All rights are saved ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])},
  "Egyptian Ministry of Environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وزارة البيئة المصرية "])},
  "Official Website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع الرسمي"])}
}