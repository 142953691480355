export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي"])},
  "EEAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environmental Affairs Agency"])},
  "YourFacilitiesMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("count")), " Facility ::: You have ", _interpolate(_named("count")), " Facilities"])},
  "FoundFacilitiesMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("count")), " Facility Found"])},
  "FoundFacilitiesMessage_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing Found "])},
  "FoundFacilitiesMessage_one": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("count")), " Facility Found"])},
  "FoundFacilitiesMessage_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("count")), " Facilities Found"])},
  "Welcome Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Egyptian National Pollutants' Inventory Program, which aims to provide a unified platform for environmental data to support sustainable planning and growth in Egypt. Our program is committed to providing accurate and up-to-date information on sources of pollution that affect the state of the environment and its impact on public health. We believe that by working together, we can create a cleaner, healthier, and sustainable future for all."])},
  "About Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Egyptian National Pollutants' Inventory Program is a comprehensive system designed to assess the sources of pollution in Egypt. Our program focuses on air, water, and waste generated from different activites. We conduct research, collect data, and analyze information to help policymakers make informed decisions about environmental protection. Our goal is to promote sustainable development and protect public health."])},
  "Organizational Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team at the Egyptian National Pollutants' Inventory Program is composed of highly skilled professionals who are dedicated to protecting the environment and promoting sustainable development. We work closely with government agencies, academias, and other stakeholders to ensure that our efforts are aligned with national and international environmental policies and regulations. Our team is committed to transparency, accountability, and professionalism in all our activities."])},
  "Publication Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are proud to share our research findings and reports with the public. Our publications cover a wide range of topics related to environmental protection, including air quality, water management, and waste management. We also collaborate with national and international organizations to produce joint publications that contribute to the global environmental discourse."])}
}